div.rbt-aux {
    transform: translateX(-15px);
}

.recharts-tooltip-wrapper {
    z-index: 9999;
}

.cut-overflow {
    word-break: break-all;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 1px;
}

.dd li.breadcrumb-item a {
    text-decoration: unset !important;
    color: silver !important;
    cursor: unset;
}

div.row {
    margin-top: 5px;
    margin-bottom: 5px;
}

button.tdButton {
    margin-left: 2px !important;
    margin-right: 2px !important;
    min-width: 35px !important;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.positive-progress {
    color: #1f4503;
    font-weight: bold;
}

.negative-progress {
    color: red;
    font-weight: bold;
}

.bold {
    font-weight: bold;
}

.currency-flag {
    margin-left: 2px;
    margin-right: 2px;
}

td.contract {
    width: 1px !important;
}

.pointer {
    cursor: pointer;
}

.pointer:hover {
    color: blue;
}

a.pointer {
    text-decoration: none !important;
    color: black;
}

tr.blocked,
textarea.blocked {
    background-color: rgb(255, 236, 255) !important;
}

tr.ongoing {
    background-color: rgb(255, 246, 236) !important;
}

tr.success {
    background-color: rgb(246, 255, 241);
}

div.carousel-item.active {
    padding-left: 5px;
    padding-right: 5px;
}

div.form-floating label {
    left: unset !important;
}

td.gray {
    background-color: rgb(231, 231, 231) !important;
}

.fgreen {
    color: #1f4503;
}

.fred {
    color: red;
}

div.lg input[type=checkbox] {
    width: 25px;
    height: 25px;
}

.pagButton {
    min-width: 40px !important;
    margin-right: 2px !important;
    margin-left: 2px !important;
}

div.pagination {
    margin-bottom: 20px;
}

div.myTable {
    position: relative;
}

.table-responsive:has(.multi-select-filter) {
    overflow-x: unset !important;
}

.multi-select-filter {
    max-width: 200px;
}

div.myTable>div.szh-menu-container {
    opacity: 0;
}

div.myTable>div.szh-menu-container:hover {
    animation: fadeIn 0.3s;
    opacity: 1;
}

table.myTable input.form-control[readonly] {
    background-color: transparent !important;
    font-size: small;
}

tr.title th {
    background-color: #2e3135 !important;
    color: white;
}

table.myTable {
    font-size: small;
}


/* top-left border-radius */

table.myTable tr:first-child th:first-child {
    border-top-left-radius: 8px;
    border-left-width: 0px !important;
    padding-left: 6px !important;
}

table.myTable tr:first-child {
    border-top-width: 0px !important;
}


/* top-right border-radius */

table.myTable tr:first-child th:last-child {
    border-top-right-radius: 8px;
    border-right-width: 0px !important;
}

.myTable ul.szh-menu {
    font-size: medium;
}

.szh-menu__item {
    font-weight: normal !important;
}

a.link {
    text-decoration: unset;
    color: black;
}

a.plink {
    text-decoration: unset;
    color: black;
    margin-right: 5px;
    float: left;
}

span.link {
    cursor: pointer;
    color: #0d6efd;
}

.brandActive0 {
    font-style: italic;
    color: gray;
}

.brandPDLT {
    font-style: italic;
    color: red;
}

td.green {
    color: #1f4503;
    cursor: pointer;
}

td.green:hover {
    font-weight: bold;
}

td.red {
    color: red;
    cursor: pointer;
}

td.red:hover {
    font-weight: bold;
}

td.proxy-client {
    width: 120px;
}

div.costs {
    font-family: monospace;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px;
    width: 800px;
    background-color: #9dff00;
    border-radius: 25px;
    margin-bottom: 25px !important;
}

div.costs div.title {
    text-align: right;
    font-weight: bold;
}

div.costs div.cost {
    text-align: left;
}

span.colHead {
    font-style: italic !important;
    padding-left: 7px;
    padding-right: 7px;
}

span.colValue {
    font-weight: bold !important;
}

.asTextarea {
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: monospace;
    white-space: pre-wrap !important;
    overflow-wrap: break-word !important;
}

div.brand-settings div.row {
    margin-top: unset !important;
}

div.standardGrid div.row:not(:first-child) {
    margin-top: 25px;
}

ul.nobullets,
ul.nobullets>li {
    margin: 0px !important;
    list-style-type: none !important;
    padding: 0px !important;
}

img.thumb {
    max-width: 100%;
    max-height: 100px;
}

.thumb.failed,
div.thumb.failed {
    background-image: url("images/no-image.jpg");
    background-repeat: no-repeat;
    width: 100px;
    height: 100px;
    cursor: unset !important;
}

span.not-supported-currency {
    margin-left: 10px;
    color: red;
    font-weight: bold !important;
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.db-summary-row {
    border-bottom-style: solid;
    border-bottom-color: silver;
    border-bottom-width: 1px;
}

.db-summary-row:nth-child(6) {
    border-bottom-style: none;
    border-bottom-color: red;
}

div.rmsc.is-invalid div.dropdown-container {
    border: 1px solid red;
}

div.rmsc.is-invalid div.dropdown-heading-value {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxMiAxMicgd2lkdGg9JzEyJyBoZWlnaHQ9JzEyJyBmaWxsPSdub25lJyBzdHJva2U9JyNkYzM1NDUnPjxjaXJjbGUgY3g9JzYnIGN5PSc2JyByPSc0LjUnLz48cGF0aCBzdHJva2UtbGluZWpvaW49J3JvdW5kJyBkPSdNNS44IDMuNmguNEw2IDYuNXonLz48Y2lyY2xlIGN4PSc2JyBjeT0nOC4yJyByPScuNicgZmlsbD0nI2RjMzU0NScgc3Ryb2tlPSdub25lJy8+PC9zdmc+");
    background-position: right 0.75rem center, center right 2.25rem;
    background-repeat: no-repeat;
    background-size: 16px;
}

a.jsoneditor-poweredBy {
    display: none;
}

.product-attribute .jsoneditor-tree {
    max-height: 450px;
}

.attr-legacy-value {
    margin-left: 5px;
    border-radius: 5px;
    border-width: 1px;
    background-color: white;
    border: 1px solid gray;
    padding: 4px;
    white-space: pre;
}

div.dropdown-content {
    z-index: 999999 !important;
}

div.carousel-inner {
    overflow: visible;
}

.orgDisabled {
    color: gray !important;
    font-style: italic !important;
    text-decoration: line-through !important;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.faSwitch {
    width: 25px !important;
    height: 25px !important;
    cursor: pointer;
    color: red;
}

.faSwitch.checked {
    color: #1f4503 !important;
}

div.nav-tabs {
    font-size: smaller;
}

tr.highlighted {
    background-color: plum;
}

.subMenuIcon {
    margin-right: 10px !important;
}

.float-right {
    float: right !important;
}

.toast-container {
    z-index: 99999 !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.hidden {
    display: none !important;
}

.rbt-token-remove-button {
    background: initial;
    border: 0px;
    padding: 1px 6px !important;
}

li.breadcrumb-item > a[role="button"] > a {
    cursor: pointer;
}

.select-rows-partial > .form-check-input:checked {
    background-color: #a9a9a9 !important;
    border-color: #a9a9a9 !important;
}

.select-rows-action-btn {
    min-width: 25px !important;
    padding: 0px !important;
    margin-left: 5px !important;
    margin-right: 0px !important;
    color: black !important;
    background-color: transparent !important;
    border-color: transparent !important;
}

.select-rows {
    position: relative;
    top: -2px !important
}

div.selector div.dropdown-container[aria-disabled=true] {
    background-color: #e9ecef;
}
